* {
  margin: 0;
  padding: 0;
}

.Toastify__toast-icon {
  width: 30px !important;
}

.Toastify__toast {
  border-radius: 6px !important;
}